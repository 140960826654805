import React from 'react'
import { Link } from 'react-router-dom'
import abimg2 from '../../images/about/directorimg.jpeg'
import shape1 from '../../images/ab-shape-1.png'
import shape2 from '../../images/ab-shape-2.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About = (props) => {
    return(
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abimg2} alt=""/>
                                <div className="wpo-ab-shape-1"><img src={shape1} alt=""/></div>
                                <div className="wpo-ab-shape-2"><img src={shape2} alt=""/></div>
                                <div className="wpo-about-exprience">
                                    <h3>18+ Years Experience</h3>
                                </div>
                                <div className="wpo-ab-img-2">
                                    {/* <img src={abimg2} alt=""/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <h4>Director’s Desk</h4>
                            <h2>We are trusted college, <b>with <span>18+ years</span> of experience.</b>
                            </h2>
                            <p>Respected Parents</p>
                            <p>
                                “Hope is believing that you have the will and the way to accomplish your goals”

                                Hello !

                                Your most welcome in our academic field. Education gains it’s significance and value. When the right kind of inputs are received from the right kind of people in the right environment.

                                In Sant Nekiram teachers training College the success is measured by five key qualities Love, Self Confidence, Discipline, Determination and Humanity. We give the education in the direction of development for these quality special emphasis is laid on participation in co-curricular activities. we made as we are following CCE i.e. continuous and Comprehensive Evaluation of a child, it has been set up to produce future leaders who are self reliant, self confident. Versatile with the courage of the conviction and endowed with a spirit of adventure and creativity.So now let start journey with hope and enthusiasm.

                                With warm regards !.</p>
                            <Link onClick={ClickHandler} className="theme-btn" 
                            // to="/about"
                            to="/"
                            >More About Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;